// extracted by mini-css-extract-plugin
export var wishlistHead = "wishlists-module--wishlistHead--SviTy";
export var wishlistRow = "wishlists-module--wishlistRow--OwoLn";
export var wishlistItemEditor = "wishlists-module--wishlistItemEditor--s02-O";
export var active = "wishlists-module--active--3a5A5";
export var wishlistItem = "wishlists-module--wishlistItem--F3Bx3";
export var wishlistEmpty = "wishlists-module--wishlistEmpty--+-BtN";
export var actions = "wishlists-module--actions--Dpu-V";
export var wishlistErrorContainer = "wishlists-module--wishlistErrorContainer--iZMLh";
export var show = "wishlists-module--show--Tdr2x";
export var wishlistError = "wishlists-module--wishlistError--q6Q8g";